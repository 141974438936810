@font-face {
  font-family: "Gilroy";
  src: url("../assets/fonts/Gilroy-ExtraBold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/Kometa_Thin.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/Kometa_Light.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/kometa.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/Kometa_Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/Kometa_Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Kometa";
  src: url("../assets/fonts/Kometa_Bold.otf");
  font-weight: bold;
  font-style: normal;
}

:root {
  --primary-color: #1E1E1E;
  --secondary-color: #01FE4A;
  --light-color: #fff;
  --info-background: #e7fbf9;
  --info-light-background: #dee8ea;
  --size-header: 3.125em;
  --size-footer: 5em;
  --shadow-dark:#00809A;

  --RANGE-VALUE: 55%;
  --C-MAIN: #9cb914;
  --C-GREY: #e3e4e8;
}

body {
  margin: 0px;
  font-family: "Kometa";
  font-style: normal;
  color: var(--primary-color);
}

* {
  box-sizing: border-box;
}

h1,
h2 {
  margin: 0px;
}
